import { Component } from '@angular/core';
import { StaticAlertsService } from 'modules/alerts/services/static-alerts.service';
import { GlobalConfig } from 'core/environment/global-config.service.ajs-upgraded-provider';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';


@Component({
  selector: 'impersonate-alert-component',
  templateUrl: './impersonate-alert.component.html'
})
export class ImpersonateAlertComponent {
  signInWording = this.globalConfig.signInWording;

  constructor(public staticAlertsService: StaticAlertsService,
    private globalConfig: GlobalConfig,
    public currentUser:CurrentUserService) {
  }
}
