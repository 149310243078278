import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAlertType } from 'modules/alerts/models/alert-type.model';
import { CACHE_TTL_TOKEN, TTL } from 'core/http-interceptors/http-caching-interceptor';


export enum AlertsTypeEndpoints {
  GET = '/a/alerts/types/',
}

@Injectable()
export class AlertTypesService {
  constructor(private http: HttpClient) {
  }

  public getTypes(): Observable<IAlertType[]> {
    return this.http.get<IAlertType[]>(
      AlertsTypeEndpoints.GET,
      {
        context: new HttpContext()
          .set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE)
      }
    );
  }
}
