<div *ngFor="let message of staticAlertsService.messages$ | async ">
  <div class="alert alert-message no-margin-bottom alert-dismissible no-border-radius alert-{{message.type?.name.toLowerCase() }}"

       *ngIf="message.visibility" style="padding-right: 15px"
       [ngClass]="{'alert-danger': 'Failure' === message.type?.name, 'alert-success': 'Resolution' === message.type.name}" role="alert">

    <div class="container-fluid" [ngSwitch]="message.alertType">
      <button type="button" class="close" data-dismiss="alert" aria-label="Close" *ngIf="!message.alwaysOnTop" (click)="staticAlertsService.dismiss(message)"><span aria-hidden="true">&times;</span></button>

      <impersonate-alert-component *ngSwitchCase="alertType.IMPERSONATE"></impersonate-alert-component>

      <div *ngSwitchDefault>
        <span [innerHTML]="message.text | markdownToHtml"></span>
      </div>
    </div>

  </div>
</div>
