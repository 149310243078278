import { IAlertType } from './alert-type.model';


export enum AlertType{
  DEFAULT,
  IMPERSONATE
}

export interface IAlert {
  id?: number;
  text: string;
  active: boolean;
  type?: IAlertType;
  visible_only_to_logged_in_users: boolean;
  alwaysOnTop?:boolean;
  alertType: AlertType;
  visibility?: boolean;
}

