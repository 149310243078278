import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { StaticAlertsService } from 'modules/alerts/services/static-alerts.service';
import _ from 'lodash';
import { ImpersonationService } from 'modules/admin/user/services/impersonation.service';
import { AlertType } from '../models/alert.model';


@Component({
  selector: 'alert-messages',
  templateUrl: './alert-messages.component.html'
})
export class AlertMessagesComponent implements OnInit {
  @Output() hasAlerts?: EventEmitter<boolean> = new EventEmitter<boolean>();
  readonly alertType = AlertType;

  constructor(public staticAlertsService: StaticAlertsService, private impersonationService: ImpersonationService) {
  }

  ngOnInit() {
    this.staticAlertsService.messages$.subscribe((messages) => {
      this.hasAlerts.next(!!_.filter(messages, 'visibility').length);
    });
  }
}
