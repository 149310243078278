import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, finalize, Observable, of, tap, Unsubscribable } from 'rxjs';
import { AlertsService } from 'modules/alerts/services/alert.service';
import { IAlert } from 'modules/alerts/models/alert.model';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { CookieService } from 'ngx-cookie-service';
import _ from 'lodash';


@Injectable()
export class StaticAlertsService implements OnDestroy {
  public messages$: Observable<IAlert[]> ;
  readonly anonAlertDismissCookie = 'kmi.lms.alertShow'; //TODO Check cookiePrefix variable

  private messagesSubject: BehaviorSubject<IAlert[]> = new BehaviorSubject<IAlert[]>([]);
  private messages: IAlert[] = [];
  private subscriber?: Unsubscribable;
  constructor(private http: HttpClient,
              private alertsService: AlertsService,
              private currentUser:CurrentUserService,
              private cookiesService:CookieService,
  ) {
    this.messages$ = this.messagesSubject.asObservable();
    this.subscriber = this.alertsService
      .get({ visibility: true }, true)
      .pipe(
        catchError(() => {
          return of(null);
        }),
        tap((message) => {
          if (message) {
            this.messages.unshift(message);
          }
        }),
        finalize(() => {
          this.subscriber.unsubscribe();
          delete this.subscriber;
        }))
      .subscribe(() => {
        this.messagesSubject.next(this.messages);
      });
  }

  dismiss(message:IAlert) {
    if (message.id) {
      if (this.currentUser.get().anonymous) {
        const date = new Date();
        const minutes = 60;

        date.setTime(date.getTime() + (minutes * 60 * 1000));

        this.cookiesService.set(this.anonAlertDismissCookie, 'dismissed', { expires: date });
      } else {
        this.alertsService.dismiss().subscribe();
      }
    }

    this.remove(message);
  }

  getAlert() {
    return this.messages$;
  }

  unshift(message:IAlert) {
    this.messages.unshift(message);
    this.messagesSubject.next(this.messages);
  }

  remove(message:IAlert) {
    this.messages = _.without(this.messages, message);
    this.messagesSubject.next(this.messages);
  }

  push(message:IAlert) {
    this.messages.push(message);
    this.messagesSubject.next(this.messages);
  }

  ngOnDestroy() {
    this.subscriber?.unsubscribe();
    delete this.subscriber;
  }
}
