import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { AlertType, IAlert } from 'modules/alerts/models/alert.model';
import {
  CACHE_NAME_TOKEN, CACHE_TTL_TOKEN, FLUSH_CACHE_TOKEN,
  TTL
} from 'core/http-interceptors/http-caching-interceptor';
import { ElmsUtils } from 'core/utils';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';


export enum AlertsEndpoints {
  GET = '/a/alerts/',
  PUT = '/a/alerts/:alertId/',
  POST = '/a/alerts/',
  DISMISS = '/a/alerts/appearance/'
}

export interface IAlertRequestParams {
  visibility: boolean
}

const ALERTS_CACHE_NAME = 'alerts';

@Injectable()
export class AlertsService {
  private defaultProps: Partial<IAlert> = {
    active: true,
    visibility: true,
    alertType: AlertType.DEFAULT
  };

  constructor(private http: HttpClient) {
  }

  public get(query?: IAlertRequestParams, throwHttpErrors = false): Observable<IAlert> {
    let params = new HttpParams();

    if (query) {
      params = params.set('visibility', query.visibility);
    }

    return this.http.get<IAlert>(
      AlertsEndpoints.GET,
      {
        params: params,
        context: new HttpContext()
          .set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors)
          .set(CACHE_NAME_TOKEN, ALERTS_CACHE_NAME)
          .set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE)
      }
    ).pipe(
      map(resource => Object.assign({}, this.defaultProps, resource))
    );
  }

  public update(alertId: number, payload: Partial<IAlert>): Observable<IAlert> {
    return this.http.put<IAlert>(
      ElmsUtils.formatUrl(AlertsEndpoints.PUT, { alertId }),
      payload,
      {
        context: new HttpContext()
          .set(FLUSH_CACHE_TOKEN, ALERTS_CACHE_NAME)
      }
    );
  }

  public save(payload: Partial<IAlert>): Observable<IAlert> {
    return this.http.post<IAlert>(
      AlertsEndpoints.POST,
      payload
    );
  }

  public dismiss(): Observable<null> {
    return this.http.post<null>(AlertsEndpoints.DISMISS, { dismissed: 'true' });
  }

  public newAlert(alertAttrs?: Partial<IAlert>): Partial<IAlert> {
    return {
      ...this.defaultProps,
      ...(alertAttrs || {})
    };
  }
}
