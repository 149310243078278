import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { AlertsService } from './services/alert.service';
import { AlertTypesService } from './services/alert-types.service';
import { AlertMessagesComponent } from 'modules/alerts/view/alert-messages.component';
import { StaticAlertsService } from 'modules/alerts/services/static-alerts.service';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { ImpersonateAlertComponent } from 'modules/alerts/view/impersonate-alert.component';
import { ImpersonationService } from 'modules/admin/user/services/impersonation.service';


@NgModule({
  imports: [
    LmsComponentsModule,
    CommonModule,
    HttpClientModule,
    UIRouterUpgradeModule
  ],
  declarations: [
    AlertMessagesComponent,
    ImpersonateAlertComponent
  ],
  providers: [
    HttpClient,
    CookieService,
    AlertsService,
    AlertTypesService,
    StaticAlertsService,
    ImpersonationService
  ],
  exports: [
    AlertMessagesComponent
  ]
})
export class AlertsModule {}
